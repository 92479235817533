import React, { useState, useEffect } from "react";
import OfferBtn from "./OfferBtn";
import Search from "./Search";

const AboutUs = () => {
  return (
    <div className="w-full min-h-[90dvh] bg-radial-gradient px-[44px] py-[15px] md:px-[78px] md:py-[40px] lg:px-[50px] xl:py-[52px] relative">
      <div className="flex flex-col justify-center items-center relative text-white">
        <h1 className="pb-[10px] font-extrabold">O NAS</h1>
        <div className="w-[350px] md:w-[750px] lg:w-[1000px] bg-black p-4 rounded-[20px] shadow-[0px_0px_20px_5px] shadow-[rgba(0,0,0,0.5)]">
          <div className="w-full h-full bg-[#262626] p-4 rounded-[10px] ">
            <h3 className="">Szanowni Państwo,</h3>
            <div>
              <h2 className="inline font-extrabold">
                <strong>Licencjonowane Biuro WERWA NIERUCHOMOŚCI </strong>
              </h2>
              prowadzi działalność w zakresie:
            </div>
            <ul>
              <li>Pośrednictwa w obrocie nieruchomościami,</li>
              <li>Oraz świadczy usługi zarządzania nieruchomościami.</li>
            </ul>
            <p>
              Naszym celem jest profesjonalna pomoc Klientom w sprzedaży,
              zakupie oraz wynajmie mieszkań, domów, działek, lokali i obiektów
              komercyjnych, zarówno na rynku wtórnym, jak i pierwotnym.
            </p>
            <p>
              Mamy również do zaoferowania{" "}
              <strong className="font-extrabold">usługi dodatkowe:</strong>
            </p>
            <ul>
              <li>zakup nieruchomości za gotówkę,</li>
              <li>przeprowadzki i transport gabarytów,</li>
              <li>
                home staging i sprzątanie nieruchomości pomoc w uzyskaniu
                kredytu,
              </li>
              <li>wycena i analiza stanu technicznego nieruchomości,</li>
              <li>doradztwo prawne, techniczne i geodezyjne,</li>
              <li>uzyskanie świadectwa charakterystyki energetycznej.</li>
            </ul>
            <p>
              Mając na względzie komfort i satysfakcję naszych Klientów
              zajmujemy się pośrednictwem w obrocie nieruchomościami jak również
              formalnościami niezbędnymi do realizacji transakcji.{" "}
              <strong className="font-extrabold">
                {" "}
                Troszczymy się o Klientów{" "}
              </strong>
              od momentu podpisania umowy pośrednictwa, aż do dnia przeniesienia
              własności.
            </p>
            <p>
              Na życzenie Klienta sprawdzamy nieruchomość pod względem prawnym,
              uzgadniamy warunki zawarcia umowy, kompletujemy dokumenty,
              pomagamy w przepisaniu mediów.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
