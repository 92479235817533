import React, { useState, useEffect } from "react";
import { collection, doc, getDoc, Timestamp } from "firebase/firestore";
import { db, storage } from "../firebase";
import { getDownloadURL, ref } from "firebase/storage";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";

interface Estate {
  id: string;
  name: string;
  location: string;
  cost: string;
  description: string;
  roomCount: string;
  surface: string;
  mainImageUrl: string;
  mediaUrls: string[] | null;
  type:
    | "Dom na Sprzedaż"
    | "Dom na Wynajem"
    | "Mieszkanie na Sprzedaż"
    | "Mieszkanie na Wynajem"
    | "Działka na Sprzedaż"
    | "Działka na Wynajem"
    | "Lokal na Sprzedaż"
    | "Lokal na Wynajem"
    | "Obiekt na Sprzedaż"
    | "Obiekt na Wynajem";
  timestamp: Timestamp;
}

interface Props {
  estateId: string;
}

const EstateSite = ({ estateId }: Props) => {
  const [estate, setEstate] = useState<Estate | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [currentMediaIndex, setCurrentMediaIndex] = useState<number>(0);

  const fetchEstate = async (estateId: string) => {
    try {
      const estateDocRef = doc(db, "estates", estateId);
      const estateDoc = await getDoc(estateDocRef);

      if (estateDoc.exists()) {
        const estateData = estateDoc.data() as Estate;
        const mainImageRef = ref(storage, estateData.mainImageUrl);
        const mainImageUrl = await getDownloadURL(mainImageRef);

        let mediaUrls: string[] = [];
        if (estateData.mediaUrls) {
          mediaUrls = await Promise.all(
            estateData.mediaUrls.map(async (mediaPath) => {
              const mediaRef = ref(storage, mediaPath);
              return await getDownloadURL(mediaRef);
            })
          );
          mediaUrls.splice(0, 0, mainImageUrl);
        }

        setEstate({ ...estateData, mainImageUrl, mediaUrls });
      } else {
        setError("Estate not found");
      }
    } catch (error) {
      console.error("Error fetching estate:", error);
      setError("Failed to fetch estate");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (estateId) {
      fetchEstate(estateId);
    }
  }, [estateId]);

  const handleNextMedia = () => {
    if (estate?.mediaUrls?.length && estate.mediaUrls.length > 0) {
      setCurrentMediaIndex((prevIndex) =>
        prevIndex === estate.mediaUrls!.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const handlePrevMedia = () => {
    if (estate?.mediaUrls?.length && estate.mediaUrls.length > 0) {
      setCurrentMediaIndex((prevIndex) =>
        prevIndex === 0 ? estate.mediaUrls!.length - 1 : prevIndex - 1
      );
    }
  };

  if (loading) {
    return (
      <div className="w-full min-h-[90dvh] bg-radial-gradient px-[44px] py-[15px] pb-[100px] md:pb-0 md:px-[78px] md:py-[40px] lg:px-[50px] xl:py-[52px] relative shadow-[0px_0px_20px_rgba(0,0,0,1)] flex justify-center items-cente">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full min-h-[90dvh] bg-radial-gradient px-[44px] py-[15px] pb-[100px] md:pb-0 md:px-[78px] md:py-[40px] lg:px-[50px] xl:py-[52px] relative shadow-[0px_0px_20px_rgba(0,0,0,1)] flex justify-center items-cente">
        {error}
      </div>
    );
  }

  const formattedContent = estate?.description.replace(/\n/g, "  \n");

  return (
    <div className="w-full min-h-[90dvh] bg-radial-gradient px-[44px] py-[15px] pb-[100px] md:pb-0 md:px-[78px] md:py-[40px] lg:px-[50px] xl:py-[52px] relative shadow-[0px_0px_20px_rgba(0,0,0,1)] flex justify-center items-start">
      {estate ? (
        <div className="flex flex-col justify-center items-center w-[900px] text-white font-[Rubik] p-[20px] rounded-[20px]">
          <div className="text-center flex flex-col justify-center items-center">
            <p className="text-[48px] drop-shadow-[0px_0px_10px_#000000] font-extrabold leading-[40px]">
              {estate.location}
            </p>
            <p className="text-[24px] drop-shadow-[0px_0px_10px_#000000] font-normal text-gray-100 leading-[40px]">
              {estate.type}
            </p>
            <p className="pb-[10px] drop-shadow-[0px_0px_10px_#000000] text-[16px] leading-[10px]">
              {estate.cost.toLocaleString() +
                " zł" +
                (parseInt(estate.roomCount) > 0
                  ? ` • ${estate.roomCount}` +
                    (parseInt(estate.roomCount) >= 5 ? " Pokoi" : " Pokoje")
                  : "") +
                " • " +
                estate.surface +
                " m²"}
            </p>
          </div>
          <div className="relative h-auto flex justify-center items-center w-[300px] md:w-[400px] lg:w-[500px]">
            {estate?.mediaUrls && estate.mediaUrls.length > 1 && (
              <>
                <button
                  onClick={handlePrevMedia}
                  className="bg-secondaryPannelBackground text-white p-2 px-4 rounded-lg mr-2"
                >
                  {"<"}
                </button>
              </>
            )}
            {/* <video
              src="https://firebasestorage.googleapis.com/v0/b/nierucciwerwa-5699a.appspot.com/o/estates%2F9FSVcoVzg01FLkHIKpLL%2Fmedia%2FIMG_4388.MOV?alt=media&token=b2e0f821-cfa2-43be-a1b4-603fc6ea5d4f"
              className="rounded-[20px] w-full max-w-[500px] border-accentYellow border-2 shadow-[0px_0px_10px_#000000]"
              autoPlay
            ></video> */}
            {estate?.mediaUrls && estate.mediaUrls.length > 0 ? (
              estate.mediaUrls[currentMediaIndex].includes(".jpeg") ||
              estate.mediaUrls[currentMediaIndex].includes(".jpg") ||
              estate.mediaUrls[currentMediaIndex].includes(".png") ||
              estate.mediaUrls[currentMediaIndex].includes(".webp") ? (
                <img
                  src={estate.mediaUrls[currentMediaIndex]}
                  alt="Property media"
                  className="rounded-[20px] w-auto h-auto max-w-[270px] max-h-[400px] md:max-w-[500px] md:max-h-[500px] border-accentYellow border-2 shadow-[0px_0px_10px_#000000]"
                />
              ) : (
                <video
                  className="rounded-[20px] w-auto h-auto max-w-[270px] max-h-[400px] md:max-w-[500px] md:max-h-[500px] border-accentYellow border-2 shadow-[0px_0px_10px_#000000]"
                  autoPlay
                  loop
                  src={estate.mediaUrls[currentMediaIndex]}
                >
                  Your browser does not support the video tag.
                </video>
              )
            ) : (
              <img
                src={estate.mainImageUrl}
                alt="Main property image"
                className="rounded-[20px] w-auto h-auto max-w-[270px] max-h-[370px] md:max-w-[500px] md:max-h-[500px] border-accentYellow border-2 shadow-[0px_0px_10px_#000000]"
              />
            )}

            {estate?.mediaUrls && estate.mediaUrls.length > 1 && (
              <>
                <button
                  onClick={handleNextMedia}
                  className="bg-secondaryPannelBackground text-white p-2 px-4 rounded-lg ml-2"
                >
                  {">"}
                </button>
              </>
            )}
          </div>

          <div className="text-left w-[300px] md:w-[400px] lg:w-[500px] mt-[30px] text-pretty">
            <ReactMarkdown
              remarkPlugins={[remarkGfm, remarkBreaks]}
              rehypePlugins={[rehypeRaw]}
            >
              {formattedContent || "Brak danych"}
            </ReactMarkdown>
          </div>
        </div>
      ) : (
        <p>No estate details available</p>
      )}
    </div>
  );
};

export default EstateSite;
